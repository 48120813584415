(function () {
  function parseHTML(str, single) {
    var frag = document.createDocumentFragment();
    var tmp = frag.appendChild(document.createElement("div"));
    tmp.innerHTML = str;
    return single ? tmp.childNodes[0] : tmp.childNodes;
  }

  if (window.ff) window.ff.parseHTML = parseHTML;
  else window.ff = { parseHTML: parseHTML };
})();
