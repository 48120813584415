/* cookies.js 1.4.2 https://github.com/franciscop/cookies.js */
var cookies = function (a, b) {
  function c(a, b) {
    a = a || {};
    for (var c in b) void 0 === a[c] && (a[c] = b[c]);
    return a;
  }
  function d(a) {
    var b = a;
    return (
      b instanceof Date || ((b = new Date()), b.setTime(b.getTime() + 1e3 * a)),
      b.toUTCString()
    );
  }
  if (
    (c(cookies, {
      expires: 31536e3,
      path: "/",
      secure: "https:" === window.location.protocol,
      nulltoremove: !0,
      autojson: !0,
      autoencode: !0,
      encode: function (a) {
        return encodeURIComponent(a);
      },
      decode: function (a) {
        return decodeURIComponent(a);
      },
      error: function (a, b, c) {
        throw new Error(a);
      },
      fallback: !1,
    }),
    (b = c(b, cookies)),
    "string" == typeof a)
  ) {
    var e = document.cookie
      .split(/;\s*/)
      .map(
        b.autoencode
          ? b.decode
          : function (a) {
              return a;
            }
      )
      .map(function (a) {
        return a.split("=");
      })
      .reduce(function (a, b) {
        return (a[b[0]] = b.splice(1).join("=")), a;
      }, {})[a];
    if (!b.autojson) return e;
    var f;
    try {
      f = JSON.parse(e);
    } catch (g) {
      f = e;
    }
    return "undefined" == typeof f && b.fallback && (f = b.fallback(a, b)), f;
  }
  for (var h in a) {
    var i = a[h],
      j = "undefined" == typeof i || (b.nulltoremove && null === i),
      k = b.autojson ? JSON.stringify(i) : i,
      l = b.autoencode ? b.encode(k) : k;
    j && (l = "");
    var m =
      b.encode(h) +
      "=" +
      l +
      (b.expires ? ";expires=" + d(j ? -1e4 : b.expires) : "") +
      ";path=" +
      b.path +
      (b.domain ? ";domain=" + b.domain : "") +
      (b.secure ? ";secure" : "");
    b.test && b.test(m), (document.cookie = m);
    var n = cookies(b.encode(h)) || "";
    i &&
      !j &&
      b.expires > 0 &&
      JSON.stringify(n) !== JSON.stringify(i) &&
      (navigator.cookieEnabled
        ? b.fallback
          ? b.fallback(a, b)
          : b.error("Cookie too large at " + i.length + " characters")
        : b.error("Cookies not enabled"));
  }
  return cookies;
};
!(function (a) {
  "object" == typeof exports && "object" == typeof module
    ? (module.exports = cookies)
    : "function" == typeof define && define.amd
    ? define("cookies", [], cookies)
    : "object" == typeof exports
    ? (exports.cookies = cookies)
    : (a.cookies = cookies);
})(this);
