(function () {
  function nextAll(el) {
    var nextAll = [];
    while ((el = el.nextElementSibling)) {
      if (el) {
        nextAll.push(el);
      }
    }
    return nextAll;
  }

  if (window.ff) window.ff.nextAll = nextAll;
  else window.ff = { nextAll: nextAll };
})();
